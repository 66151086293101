import axios from 'axios';

const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    // baseURL: 'https://www.easy-mock.com/mock/592501a391470c0ac1fab128',
    timeout: 5000000
});

service.domain = '';
service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
service.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return response.data;
        } else {
            alert(response.data.message);
            Promise.reject();
        }
    },
    error => {
        // if (error.response.data.message == '请重新登录') {
        //     // localStorage.removeItem("ms_username");
        //     // localStorage.removeItem("adminInfo");
        //     location.reload();
        // } else
        if(error.response.data.code == 499) {
            localStorage.removeItem("ms_username");
            localStorage.removeItem("adminInfo");
            location.reload();
            alert(error.response.data.message);
            window.alert = function() { return false; }
        } else {
            alert(error.response.data.message);
        }
        return Promise.reject(error);
    }
);

export default service;
